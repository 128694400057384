import React from "react";
import btLogo from "../../images/logos/logo-black.svg"
import btWebLogo from "../../images/logos/bittorrent-web-icon.svg"
import utLogo from "../../images/logos/utorrent-black.svg"
import utWebLogo from "../../images/logos/utorrent-web-icon.svg"
import downloadIcon from "../../images/icons/download-icon.svg"
import HelpCenterLinks from "./helpCenterLinks"
import useTranslations from "../../utils/useTranslations";

export default (props) => {
    const t = useTranslations(props.text)

    function downloadClicked(webAd) {
        window.open(webAd.link)
    }


    let products = []
    if(typeof window !== 'undefined'){
        if (props.productInfo.showClassic) {
            let link = "";

            if (window.gatsbyIsMac) {
                link = props.productInfo.brand === "BitTorrent" ? HelpCenterLinks.BT_CLASSIC_MAC : HelpCenterLinks.UT_CLASSIC_MAC
            } else {
                link = props.productInfo.brand === "BitTorrent" ? HelpCenterLinks.BT_CLASSIC_WIN : HelpCenterLinks.UT_CLASSIC_WIN
            }


            products.push({
                brand: props.productInfo.brand,
                logo: props.productInfo.brand === "BitTorrent" ? btLogo : utLogo,
                product: "Classic",
                priority: 1,
                link: link
            })
        }

        if (props.productInfo.showWeb) {
            let link = "";

            if (window.gatsbyIsMac) {
                link = props.productInfo.brand === "BitTorrent" ? HelpCenterLinks.BT_LINK_WEB_MAC : HelpCenterLinks.UT_LINK_WEB_MAC
            } else {
                link = props.productInfo.brand === "BitTorrent" ? HelpCenterLinks.BT_LINK_WEB_WIN : HelpCenterLinks.UT_LINK_WEB_WIN
            }

            products.push({
                brand: props.productInfo.brand,
                logo: props.productInfo.brand === "BitTorrent" ? btWebLogo : utWebLogo,
                product: "Web",
                priority: 2,
                link: link
            })
        }

        if (props.productInfo.showAndroid) {
            products.push({
                brand: props.productInfo.brand,
                logo: props.productInfo.brand === "BitTorrent" ? btLogo : utLogo,
                product: "Android",
                priority: 3,
                link: props.productInfo.brand === "BitTorrent" ? HelpCenterLinks.BT_LINK_ANDROID : HelpCenterLinks.UT_LINK_ANDROID
            })
        }
        products.sort((a,b)=>{
            if(a.product === props.productInfo.name){
                return -1;
            }else if (b.product === props.productInfo.name){
                return 1
            }

            return (a.priority - b.priority);
        })
    }


    return <>
            {products.length > 0 &&<div className="help-center-web-ad ml-auto">
                {products.map(webAd => <div className={"product-download"}>
                    <div className="product-description">
                        <div className="logo"><img className="logo" src={webAd.logo} /></div>
                        <span className="brand">{webAd.brand}</span>
                        <span className="name">{webAd.product}</span>
                    </div>
                    <div className="d-flex pt-2 pb-3 pointer" onClick={() => downloadClicked(webAd)}>
                        <span className={"download-text"}>{t("torrentAdDownload")}</span>
                        <div><img src={downloadIcon} alt={`download ${webAd.brand} ${webAd.product}`} /></div>
                    </div>
                    </div>
                )}

            </div>}
        </>
}