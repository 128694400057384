import React, {useState} from "react"
import {navigate} from "gatsby";
import HelpCenterLinks from "./helpCenterLinks"
import HelpCenterUserLogin from "./helpCenterUserLogin";
import PremiumIcon from "../../images/icons/premium-icon.svg"
import useTranslations from "../../utils/useTranslations";


export default (props) => {
    const t = useTranslations(props.text)

    let userInfo = null;
    if (typeof window !== 'undefined') {
      userInfo = sessionStorage.getItem(HelpCenterLinks.USER_INFO);
    }

    let loggedIn = userInfo !== null
    const [showLogin, updateShowLogin] = useState(false);

    function buttonClicked(){
        updateShowLogin(true)
    }

    function closeLogin(){
        updateShowLogin(false)
    }

    function showPremium(userInfo){
        if (typeof window !== 'undefined') {
            sessionStorage.setItem(HelpCenterLinks.USER_INFO,JSON.stringify(userInfo));
        }
        navigate(HelpCenterLinks.homeLink)
    }


    return <>
             {!loggedIn && <div className="help-center-paid-support p-4 mb-5 ml-auto">
                 {showLogin && <HelpCenterUserLogin closeLogin={closeLogin} onSuccess={showPremium} text={props.text}/>}
                    <div className="d-flex">
                        <img className="mx-auto" src={PremiumIcon} alt={"premium member icon"}/>
                    </div>
                    <div className="text">{t("paidMember")}</div>
                    <div className="help-center-button button p-3" onClick={()=>buttonClicked()}>{t("premiumButton")}</div>
                </div>}
        </>
}