import React from "react"
import HelpCenterLinks from "../../components/help-center/helpCenterLinks";
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterPremiumSupport from "../../components/help-center/helpCenterPremiumSupport";
import HelpCenterBlog from "../../components/help-center/helpCenterBlog";
import HelpCenterTorrentWebAd from "../../components/help-center/helpCenterTorrentWebAd";
import dropDownIcon from "../../images/icons/drop-down-icon.svg"
import btLogo from "../../images/logos/logo-black.svg"
import utLogo from "../../images/logos/utorrent-black.svg"
import HelpCenterBreadCrumb from "../../components/help-center/helpCenterBreadCrumb";
import HelpCenterRating from "../../components/help-center/helpCenterRating";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {Link, navigate} from "gatsby";
import useTranslations from "../../utils/useTranslations";

export default ({data, pageContext: {solutionInfo,productInfo, applications, text}}) => {
    const t = useTranslations(text)


    function getBreadCrumb() {
        let breadCrumb = [{
            name: t("helpCenter"),
            link: HelpCenterLinks.homeLink,
            isLast: false,
        }];

        breadCrumb.push({
            name: productInfo.brand + " "+solutionInfo.name,
            link: "",
            isLast: true,

        })

        return breadCrumb;
    }


    return (
        <>
            <div className="help-center-product-page">
                <HelpCenterSearch text={text}/>
                <HelpCenterBreadCrumb breadCrumb={getBreadCrumb()} />

                <div className="body pb-4">
                    <div className="m-0 p-0 ">

                        <UncontrolledDropdown>
                            <DropdownToggle className="application-selector">

                                <img className="dropdown-logo" src={productInfo.brand === "BitTorrent" ? btLogo : utLogo}
                                     alt={productInfo.brand + " logo"} /> <b>{productInfo.brand}</b> {solutionInfo.name} <img
                                className="dropdown-icon" src={dropDownIcon} alt={"drop down"} />

                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-container" flip={false}>
                                {applications.map(app =>
                                        <>
                                    {!app.isSinglePage && app.brand === productInfo.brand &&
                                        <DropdownItem className="app-link-container" key={app.name} onClick={()=>{navigate(app.link)}}>
                                        <span className={"font-weight-bold brand-font"}>{productInfo.brand}</span> {app.name}
                                        </DropdownItem>
                                    }
                                    </>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <div className="folder-container">
                            {solutionInfo.folderIndexes.map(folder =>
                            <>
                                { folder.articleList !== undefined &&
                                    <div className="category-column" key={folder.title}>
                                        <div className="header-text">
                                            {folder.title}
                                        </div>
                                        <div>
                                            {folder.articleList.map(article =>
                                                <div className="link-holder" key={article.id}>
                                                    <Link to={HelpCenterLinks.articleLink("en", article.id,article.title)}
                                                       className="link-text">{article.title}</Link>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                            )}
                        </div>
                    </div>

                    <div>
                        <HelpCenterPremiumSupport text={text}/>

                        <HelpCenterBlog  text={text}/>

                        <HelpCenterTorrentWebAd productInfo={productInfo}  text={text}/>

                        <HelpCenterRating  text={text}/>

                    </div>
                </div>


            </div>

    </>
    )
}